import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { NgFor, NgIf } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { flatMap as _flatMap, cloneDeep, Dictionary, find, first, intersectionWith, uniq } from "lodash";
import { CharDataTableComponent } from "rl-common/components/char-data";
import { VariesByCatalogWizardStepComponent } from "rl-common/components/rights/varies-by-catalog/varies-by-catalog-wizard-step/varies-by-catalog-wizard-step.component";
import { CharDataType, CharTypeId, SystemIndicators } from "rl-common/consts";
import { CharDataModifyAction } from "rl-common/models/char-data-modify-action";
import { ICharDataMoneyValue } from "rl-common/models/i-char-data-money-value";
import { ICharLovMetaData } from "rl-common/models/i-char-lov-meta-data";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicDataCollection } from "rl-common/models/i-characteristic-data-collection";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { RightsService } from "rl-common/services/rights/rights.service";
import { SearchService } from "rl-common/services/search/search.service";
import { SessionService } from "rl-common/services/session.service";
import { TablesService } from "rl-common/services/tables/tables.service";
import { VariesByCatalogService } from "rl-common/services/varies-by-catalog.service";
import { IDUtil } from "rl-common/utils/id.util";
import { Observable, of, Subscription } from "rxjs";
import { catchError, filter, finalize, flatMap, map, switchMap, take, tap } from "rxjs/operators";
import { ComponentRelationshipsComponent } from "../../associations/entity-relationships/component-relationships/component-relationships.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { CharacteristicUtil } from "./../../../utils/characteristic.util";
import { IEntityCreatedEvent } from "./models/i-entity-create-event";
import { IRelationshipsUpdatedEvent } from "./models/i-relationships-updated-event";

@Component({
	selector: "rl-create-new-char-data",
	templateUrl: "./create-new-char-data.component.html",
	styleUrls: ["./create-new-char-data.component.scss"],
	providers: [ParentEntityService],
	imports: [ArchwizardModule, NgIf, ReactiveFormsModule, NgFor, ComponentRelationshipsComponent, CharDataTableComponent, LoaderComponent, VariesByCatalogWizardStepComponent]
})
export class CreateNewCharDataComponent implements OnInit, AfterViewChecked, OnDestroy {

	@Input()
	availableTemplates: ICharacteristicTemplate[];

	@Input()
	charTypeId: number;

	@Input()
	parentEntityId: string;

	@Input()
	baseEntityId: string;

	@Input()
	isVariesByCatalog: boolean;

	@Input()
	isRelative: boolean;

	@Output()
	onCreate = new EventEmitter<IEntityCreatedEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	@ViewChild(WizardComponent)
	wizard: WizardComponent;
	wizardStep = 0;

	@ViewChild(VariesByCatalogWizardStepComponent)
	variesByComponent: VariesByCatalogWizardStepComponent;

	@ViewChildren("table")
	charDataTables: QueryList<CharDataTableComponent>;
	table: CharDataTableComponent;

	_template: ICharacteristicTemplate;

	emptyCharData: ICharacteristicData[] = [];
	templateMetaData: ICharacteristicMetaDataCollection;

	charData: ICharacteristicData[];
	relationships: { [charTypeId: number]: IRecordTitle[] } = {};
	parentCharTypeId: number;
	hasCatalogParent: boolean;
	parentCharData: ICharacteristicDataCollection;

	editMode = true;
	isSaving = false;
	isCurrencyValidationEnabled = false;
	chooseTemplate: UntypedFormControl;

	isMultiple = true;
	bulkCdc: { [recordId: number]: ICharacteristicData[] } = {};
	headers: ICharacteristicMetaData[] = [];
	requiredCharTypeDict: { [charTypeId: number]: boolean } = {};
	lovMap: { [charValueSourceId: string]: ICharLovMetaData };
	selectedStateDictionary: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> } = {};

	private readonly _tablesAndRights = [CharTypeId.Usage, CharTypeId.Right];

	private readonly _subscriptions: Subscription[] = [];

	createEntityMetaSub: Subscription;

	@Input()
	set template(template: ICharacteristicTemplate) {
		const oldTemplateId = this._template ? this._template.templateID : null;
		this._template = template;
		if (template && template.templateID !== oldTemplateId && this.charTypeId) {
			this.loadCreateMetaData();
		}
	}

	get template() {
		return this._template;
	}

	get charDataLoaded() {
		return this.emptyCharData && this.templateMetaData;
	}

	get isCharDataValid() {
		return this.table && this.table.tableFormGroup && this.table.tableFormGroup.valid;
	}

	get isTablesOrRights() {
		return this._tablesAndRights.includes(this.charTypeId);
	}

	get isVaried() {
		return this._variesByService.isVaried;
	}

	get areRelsValid() {
		const assocCharTypeIds = [CharTypeId.Property, CharTypeId.User, CharTypeId.Project, CharTypeId.Mock];
		const invalidRequired = assocCharTypeIds
			.filter(charTypeId => this.selectedStateDictionary[charTypeId]?.isValid === false);
		return invalidRequired.length === 0;
	}
	changeTemplate() {
		this.selectedStateDictionary = [];
		this.switchTemplate(+this.chooseTemplate.value);
	}
	switchTemplate(tid: number) {
		this.template = this.availableTemplates.find(t => t.templateID === tid);
		this.loadCreateMetaData();
	}

	constructor(
		private readonly _sessionService: SessionService,
		private readonly _entityService: EntityService,
		private readonly _variesByService: VariesByCatalogService,
		private readonly _rightsService: RightsService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _cdRef: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _searchService: SearchService,
		private readonly _tableService: TablesService,
		private readonly _featureService: FeatureService) { }

	ngOnInit() {
		this.isMultiple = this.charTypeId !== CharTypeId.Amount;
		this.chooseTemplate = this.formBuilder.control(this.template?.templateID);
		this.parentCharTypeId = IDUtil.splitEntityID(this.parentEntityId).charTypeID;
		this.hasCatalogParent = this.parentCharTypeId === CharTypeId.Property;
		this.loadCreateMetaData();

		const sub = this._featureService.isEnabled$(FeatureKeys.CurrencyValidation).subscribe(isEnabled => {
			this.isCurrencyValidationEnabled = isEnabled;
		});
		this._subscriptions.push(sub);
	}

	ngAfterViewChecked() {
		this._cdRef.detectChanges();
		const sub = this.charDataTables.changes.subscribe(() => {
			const tables = this.charDataTables.toArray();
			this.table = first(tables);
		});
		this._subscriptions.push(sub);
	}

	private isCreatingTableOnAmount() {
		const parentCharType = IDUtil.splitEntityID(this.parentEntityId).charTypeID;
		return parentCharType === CharTypeId.Usage && this.charTypeId === CharTypeId.Amount;
	}

	private loadCreateMetaData() {
		this.templateMetaData = null;
		this.emptyCharData = null;

		if (!this.template) {
			return;
		}

		this.createEntityMetaSub?.unsubscribe();
		// TODO: Can we replace this with one config now?
		this.createEntityMetaSub = this._entityService.getCreateEntityMetaData(this.charTypeId, this.template.templateID, this.baseEntityId, this.parentEntityId)
			.pipe(take(1))
			.subscribe(result => {
				this.parentCharData = result.parentCharData;
				this.emptyCharData = result.emptyCharData.characteristicDatas;
				this.templateMetaData = result.emptyCharData.templateMetaData;
				this.requiredCharTypeDict = result.requiredCharTypeDict;
				this.lovMap = result.lovMap;
				this._parentEntityService.clear();
				this._parentEntityService.initCreateParent(this.charTypeId, this.template.templateID, this.baseEntityId, this.parentEntityId, result.parentRelRecId);
				if (this.isCreatingTableOnAmount()) {
					this.copyCharDataToChild();
				}
				this.charData = cloneDeep(this.emptyCharData);
			});
	}

	private copyCharDataToChild() {
		const matchingCharData = intersectionWith(this.templateMetaData.characteristicMetaDatas, this.parentCharData.templateMetaData.characteristicMetaDatas,
			(cmd: ICharacteristicMetaData, parentCmd: ICharacteristicMetaData) => cmd.systemIndicator !== -1 && cmd.tagLabel === parentCmd.tagLabel);

		this.emptyCharData = _flatMap(matchingCharData, cmd => {
			const parentCmd = this.parentCharData.templateMetaData.characteristicMetaDatas.find(pCmd => pCmd.tagLabel === cmd.tagLabel);
			const newCds = this.parentCharData.characteristicDatas.filter(cd => cd.charactersticID === parentCmd.characteristicID).map(parentCd => {
				const cd: ICharacteristicData = {
					recordCharacteristicID: 0,
					charactersticID: cmd.characteristicID,
					value: parentCd.value,
					valueID: parentCd.valueID
				};
				return cd;
			});
			const lovs = cmd.charValueSourceID && this.lovMap[cmd.charValueSourceID] ? this.lovMap[cmd.charValueSourceID].listOfValues : null;
			return CharacteristicUtil.modifyCharData(cmd, [], CharDataModifyAction.Add, newCds, lovs);
		});
	}

	updateRelationships(event: IRelationshipsUpdatedEvent) {
		this.relationships = event.selectedRelationships;
	}

	updateRelationshipStates(states: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> }) {
		this.selectedStateDictionary = states;
	}

	cancel() {
		this.onCancel.emit();
	}

	save() {
		if (this._variesByService.isVaried) {
			if (this.wizardStep === 0) {
				this._subscriptions.push(this.getRelationships().pipe(
					tap(relationships => {
						this.relationships[CharTypeId.Property] = relationships;
						this.setupVariationWizard();
					})
				).subscribe());
			} else {
				this.saveVariationWizard();
			}
		} else {
			this.isSaving = true;
			const parentCharTypeId = this._parentEntityService.parent.charTypeId;
			const parentRecordId = this._parentEntityService.parent.recordId;
			const parentEntityId = IDUtil.toID(this._sessionService.divId, parentCharTypeId, parentRecordId);

			const sub = this.allowSaveContinue(this.charTypeId, parentCharTypeId)
				.pipe(
					filter(x => x),
					switchMap(() => this._entityService.createEntity(this.charTypeId, this.template.templateID, this.charData, parentEntityId, this.selectedStateDictionary)),
					tap(result => this.onCreate.emit({ entityId: result.entityId })),
					catchError(err => {
						const statusCode = err.status;
						if (statusCode < 500) {
							this._growlerService.error().growl(err?.error?.message ?? `Create Failed`);
						}
						throw err;
					}),
					finalize(() => {
						this.isSaving = false;
					})
				).subscribe();

			this._subscriptions.push(sub);
		}
	}

	getRelationships() {
		if (this.hasCatalogParent) {
			const titleId = this.parentCharData.templateMetaData.characteristicMetaDatas.find(x => x.tagLabel === "entity_title").characteristicID;
			const title = this.parentCharData.characteristicDatas.find(x => x.charactersticID === titleId).value;
			const recordTitles: IRecordTitle[] = [{ recordId: IDUtil.splitEntityID(this.parentEntityId).recID, title }];

			return of(recordTitles);
		} else {
			const state = this.selectedStateDictionary[CharTypeId.Property];
			if (!state.gridSelected.isAllSelected) {
				const selectedCatalogs = Array.from(state.gridSelected.selectedValues || []).map(x => ({ recordId: x.recordId, title: x.title } as IRecordTitle));

				return of(selectedCatalogs);
			} else {
				return this._searchService.search(CharTypeId.Property, "", null, state.model).pipe(
					map(searchResponse => searchResponse.documents.map<IRecordTitle>(x => ({ recordId: x.recordID, title: x.title })))
				);
			}
		}
	}

	private allowSaveContinue(charTypeId: number, parentCharTypeId: number): Observable<boolean> {
		if (charTypeId === CharTypeId.Amount && parentCharTypeId === CharTypeId.Invoice) {
			const amountCurrencySymbol = CharacteristicUtil.getLocalCurrency(charTypeId, this.templateMetaData.characteristicMetaDatas, this.charData);
			const invoiceCurrencySymbol = CharacteristicUtil.getLocalCurrency(parentCharTypeId, this.parentCharData.templateMetaData.characteristicMetaDatas
				, this.parentCharData.characteristicDatas);


			if (amountCurrencySymbol && invoiceCurrencySymbol && amountCurrencySymbol !== invoiceCurrencySymbol) {
				this._growlerService.error("Error").growl(`Amount's selected Currency ${amountCurrencySymbol} does not match with associated Invoice Currency ${invoiceCurrencySymbol}`);
				return of(false);
			}
		}

		if (this.isCurrencyValidationEnabled && charTypeId === CharTypeId.Amount && parentCharTypeId === CharTypeId.Relationship && this._parentEntityService?.parent?.parent.charTypeId == CharTypeId.Usage) {
			const feeChar = first(this.templateMetaData.characteristicMetaDatas.filter(x => x.systemIndicator == SystemIndicators.Amount && x.dataTypeID == CharDataType.Money));

			if (!feeChar) {
				return of(true);
			}

			const charData = this.charData.find(x => x.charactersticID == feeChar.characteristicID);

			if (!charData) {
				return of(true);
			}

			const money: ICharDataMoneyValue = JSON.parse(charData.value);

			if (!money.locCur) {
				return of(true);
			}

			// first parent is relationship between table and module, so you have to go to next parent which is the table
			return this._tableService.canCreateChildAmount(this._parentEntityService.parent?.parent?.recordId, money.locCur)
				.pipe(
					tap(result => {
						if (!result) {
							this._growlerService.error().growl("Error: Amounts of mixed currencies not allowed under single table row");
						}
					})
				);
		}

		return of(true);
	}

	setupVariationWizard() {
		this.wizard.goToNextStep();
		this.wizardStep++;
		// TODO: We may want a more robust solution than this in the future
		document.querySelector(`.modal-header`).scrollIntoView();
	}

	saveVariationWizard() {
		const variedFields: string[] = Object.keys(this._variesByService.variedFields).map(sysIndStr => {
			const sysInd = Number(sysIndStr);
			if (this._variesByService.variedFields[sysInd]) {
				return find(this.templateMetaData.characteristicMetaDatas, x => x.systemIndicator === sysInd).tagLabel;
			}
		});

		const nonExclusiveFix = this.formatCollection(false);
		const exclusiveFix = this.formatCollection(true);

		const optimizeRights = this.variesByComponent.optimizeRights;

		this._rightsService.createVariedRights(variedFields, optimizeRights, this.parentEntityId, this.template.templateID, [],
			nonExclusiveFix, exclusiveFix).pipe(
				flatMap(response => this._modalService.jobProgress(response.jobId))
			).subscribe(() => {
				this.isSaving = false;
				this.onCreate.emit({ entityId: null });
			});

	}

	formatCollection(isExclusive: boolean): { [recordId: number]: { [tagLabel: string]: ICharacteristicData[] } } {
		const collection = isExclusive ? this.variesByComponent.exclusiveCharDataCollection : this.variesByComponent.nonExclusiveCharDataCollection;

		const rtn: { [recordId: number]: { [tagLabel: string]: ICharacteristicData[] } } = {};
		Object.keys(collection).forEach(recordIdStr => {
			const recordId = Number(recordIdStr);
			if (!this.variesByComponent.isValid(recordId, isExclusive)) {
				return;
			}
			const chars: Dictionary<ICharacteristicData[]> = collection[recordId];
			rtn[recordId] = {};
			const charIds = uniq(_flatMap(chars, y => y.map(x => x.charactersticID)));
			charIds.forEach(charId => {
				const tagLabel = find(this.templateMetaData.characteristicMetaDatas, x => x.characteristicID === charId).tagLabel;
				rtn[recordId][tagLabel] = chars[charId];
			});

			const hasExclusivity = !!find(this.templateMetaData.characteristicMetaDatas, x => x.tagLabel === "exclusivity");

			if (hasExclusivity) {
				if (rtn[recordId]["exclusivity"] && rtn[recordId]["exclusivity"].length > 0) {
					rtn[recordId]["exclusivity"][0].value = isExclusive ? "Exclusive" : "Non-Exclusive";
					rtn[recordId]["exclusivity"][0].valueID = isExclusive ? 1 : 2;
				} else {
					const exclusivityCMD = find(this.templateMetaData.characteristicMetaDatas, x => x.tagLabel === "exclusivity");

					const exclusiveCharData: ICharacteristicData = {
						charactersticID: exclusivityCMD.characteristicID,
						recordCharacteristicID: 0,
						value: isExclusive ? "Exclusive" : "Non-Exclusive",
						valueID: isExclusive ? 1 : 2
					};

					rtn[recordId]["exclusivity"] = [exclusiveCharData];
				}
			}
		});

		return rtn;
	}

	back() {
		this.wizard.goToPreviousStep();
		this.wizardStep--;
	}

	requiredRelsCheck() {
		const state = this.selectedStateDictionary[CharTypeId.Property];
		return !this._variesByService.isVaried || this.hasCatalogParent || (state && state.selectedCount > 0);
	}

	wizardValidationCheck() {
		return !this.variesByComponent || this.variesByComponent.isFormValid();
	}

	ngOnDestroy() {
		this._variesByService.reset();
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
